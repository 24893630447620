






























































































import Vue from "vue";
// import { defineComponent } from "vue";
import store from "@/store";
import { getModule } from "vuex-module-decorators";
import SuperuserModule from "@/store/vuex-modules/superuser";
import AttendeeSelectionModule from "@/store/vuex-modules/attendeeSelection";

import { AttendeeDataObject, SuperUserData, AttendeeSearchResult } from "@/types/interfaces";
import Spinners from "@/components/utilities/Spinners.vue";
import AttendeeSearchSelector from "@/components/shared/AttendeeSearchSelector.vue";

const superusersStore = getModule(SuperuserModule);
const selectionStore = getModule(AttendeeSelectionModule);

export default Vue.extend({
    components: {
        Spinners,
        AttendeeSearchSelector
    },
    data() {
        return {
            isModalActive: false,
            currentUsers: [] as Array<AttendeeDataObject>,
            hasMadeChanges: false
        }
    },
    computed: {
        superusers(): Array<SuperUserData> {
            return superusersStore.superusers;
        },
        isLoading(): boolean {
            return superusersStore.loading;
        },
        sortedUsers(): Array<AttendeeDataObject> {
            return [...this.currentUsers].sort((a, b) => (a.lastName || "").localeCompare(b.lastName || ""))
        },
        currentUserIds(): Array<string> {
            return this.currentUsers.map((user) => user.attendeeId || "").filter((id) => id);
        },
        selectedAttendees(): Array<AttendeeDataObject | AttendeeSearchResult> {
            return selectionStore.selectedAttendees;
        },
        notificationMessage(): string {
            return superusersStore.message;
        },
        notificationClasses(): string {
            return superusersStore.notificationClasses;
        },
        userInfo(): Record<string, string | boolean> {
            return store.getters.userInfo;
        },
        isMatrix(): boolean {
            return `${this.userInfo.email}`.includes("@matrixgroup.net");
        }
    },
    methods: {
        canBeRemoved(email: string | undefined): boolean {
            return this.isMatrix || !email || !email.includes("@matrixgroup.net");
        },
        async removeSuperuserAccess(attendeeId: string) {
            const index = this.currentUsers.findIndex((attendee) => attendee.attendeeId && attendee.attendeeId === attendeeId);

            if(index != -1) {
                this.currentUsers.splice(index, 1);
                this.hasMadeChanges = true;
            }
        },
        async saveChanges() {
            this.hasMadeChanges = false;
            await superusersStore.updateSuperusers([...new Set([...this.currentUserIds])]);
            this.currentUsers = [...this.superusers];
        },
        openModal() {
            this.isModalActive = true;
        },
        resetModal() {
            this.isModalActive = false;
        },
        async loadSuperUsers(): Promise<void> {
            await superusersStore.getSuperUsers();
            this.currentUsers = [...this.superusers];
        },
        addSuperUsers(): void {
            this.selectedAttendees.forEach((attendee) => this.currentUsers.push(attendee as AttendeeDataObject));
            this.hasMadeChanges = true;
            this.resetModal();
            selectionStore.clearAttendeeSelection();
        },
        clearNotification(): void {
            superusersStore.clearNotification();
        }
    },
    mounted() {
        this.loadSuperUsers();
    }
});
