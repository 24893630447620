import axios from "axios";
import { getApiClient } from "@/services/api";
import { Action, Module, Mutation, VuexModule } from "vuex-module-decorators";
import Store from "../index";

import { SuperUserData } from "@/types/interfaces";

const endpoint = "superusers";

@Module({
    dynamic: true,
    store: Store,
    name: "SuperuserModule",
    namespaced: true
})
export default class Superuser extends VuexModule {
    superusers: Array<SuperUserData> = [];
    loading = true;
    saveStatus = "";

    @Mutation
    public setSuperusers(data: []) {
        this.superusers = data;
        this.loading = false;
    }

    @Mutation
    public setLoading(data: boolean) {
        this.loading = data;
    }

    @Mutation
    public setSaveStatus(data: string) {
        this.saveStatus = data;
    }

    get notificationClasses(): string {
        return this.saveStatus === "error" ? "is-danger" : "is-success";
    }

    get message(): string {
        if (this.saveStatus === "saved") {
            return "Superusers successfully updated.";
        } else if (this.saveStatus === "error") {
            return "There was an error saving superusers.";
        }

        return "";
    }

    @Action({ commit: "setSuperusers" })
    async getSuperUsers() {
        const token = this.context.rootGetters.idToken;

        this.context.commit("setLoading", true);

        return new Promise((resolve, reject) => {
            getApiClient()
                .get(`/${endpoint}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                })
                .then((response) => {
                    return resolve(response.data);
                })
                .catch((error) => {
                    return reject(error);
                })
                .finally(() => {
                    this.context.commit("setLoading", false);
                });
        });
    }
    //  PUT request, To Delete and Add new superusers
    @Action({ commit: "setSuperusers", rawError: true })
    async updateSuperusers(payload: Array<string>) {
        const token = this.context.rootGetters.idToken;

        return new Promise((resolve, reject) => {
            getApiClient()
                .post(
                    `${endpoint}`,
                    {
                        ids: payload
                    },
                    {
                        headers: { Authorization: `bearer ${token}` }
                    }
                )
                .then((response) => {
                    this.context.commit("setSaveStatus", "saved");
                    return resolve(response.data.users);
                })
                .catch((error) => {
                    this.context.commit("setSaveStatus", "error");
                    return reject(error);
                });
        });
    }

    @Action({ commit: "setSaveStatus", rawError: true })
    async clearNotification() {
        return "";
    }
}
